import styled, { css } from 'styled-components/macro';

export const StyledMaxWidthContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `}
`;
export const StyledAbsoluteFitContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

export const StyledClickable = styled.div`
  cursor: pointer;
`;
