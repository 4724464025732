import { HeroProps } from './hero.props';

export const HeroPresets: Partial<HeroProps> = {
  hasOverlay: false,
  overlayColor: 'black',
  overlayOpacity: 0.5,
  variant: 'media',
  height: {
    lg: '100vh',
    md: '100vw',
    sm: '125vw',
  },
  backgroundImageSrc: 'pages/home/home-big1@2x.jpg',
  backgroundPosition: 'center',
  backgroundImageHeight: '100%',
  backgroundColor: 'lightGrey',
  scrollable: false,
  alt: '',
};
