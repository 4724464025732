import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HeroProps } from './hero.props';
import {
  ScrollIconContainer,
  StyledHero,
  StyledHeroVideo,
  StyledImageContainer,
  StyledScrollAnchor,
} from './hero.styles';
import { HeroPresets } from './hero.presets';
import { StyledAbsoluteFitContainer } from '../containers';
import { Carousel } from 'core/carousel';
import { isLargeScreen } from 'helpers/layout.helpers';
import { Icon } from 'core/icon';

export const Hero: React.FunctionComponent<HeroProps> = (props: HeroProps) => {
  const {
    videoUrl,
    videoSmallerUrl,
    content,
    variant,
    carouselProps,
    backgroundImageSrc,
    backgroundImage,
    backgroundImageHeight,
    backgroundColor,
    backgroundObjectPosition,
    aspectRatio,
    scrollable,
    alt,
    ...others
  } = Object.assign({}, HeroPresets, props);
  const [canPlayVideo, setCanPlayVideo] = useState(false);
  const [isClient, setIsClient] = useState(false);

  const scrollAnchorRef = useRef(null);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const scrollOutOfView = useCallback(() => {
    scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <StyledHero aspectRatio={aspectRatio} {...others}>
      {variant !== 'carousel' && (
        <StyledImageContainer
          src={backgroundImageSrc}
          backgroundColor={backgroundColor}
          imageHeight={backgroundImageHeight}
          aspectRatio={aspectRatio}
          renderMode="always"
          objectPosition={backgroundObjectPosition}
          alt={alt}
        />
      )}

      {isClient && variant === 'media' && videoUrl && (
        <StyledHeroVideo
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
          onCanPlayThrough={() => setCanPlayVideo(true)}
          hidden={!canPlayVideo}
          src={isLargeScreen() ? videoUrl : videoSmallerUrl || videoUrl}
        />
      )}

      {variant === 'carousel' && (
        <Carousel
          width="100vw"
          height={others.height}
          {...(carouselProps || {})}
        />
      )}
      {variant === 'media' && (
        <StyledAbsoluteFitContainer>{content}</StyledAbsoluteFitContainer>
      )}
      {scrollable && (
        <ScrollIconContainer>
          <Icon
            name="arrow-down"
            color="white"
            width="16px"
            height="20px"
            cursor="pointer"
            onClick={scrollOutOfView}
          />
        </ScrollIconContainer>
      )}

      <StyledScrollAnchor id="scroll-anchor" ref={scrollAnchorRef} />
    </StyledHero>
  );
};
