import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';
import { HeroProps } from './hero.props';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import {
  calculateDimensionsFromAspectRatio,
  generateStylesForResolutionAwareProps,
} from 'helpers/layout.helpers';
import { ImageContainer } from 'layout/image-container';
import { ImageContainerProps } from 'layout/image-container/image-container.props';

export const StyledHero = styled.div<HeroProps>`
  ${({
    hasOverlay,
    overlayColor,
    overlayOpacity,
    backgroundPosition,
    height,
    maxHeight,
    minHeight,
    aspectRatio,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'background-position',
        resolutionAwareProp: backgroundPosition,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: aspectRatio,
        valueFormatter: value => {
          if (value) {
            return calculateDimensionsFromAspectRatio(value).height;
          }

          return null;
        },
      },
      {
        cssProperty: 'max-height',
        resolutionAwareProp: maxHeight,
      },
      {
        cssProperty: 'min-height',
        resolutionAwareProp: minHeight,
      },
    ];

    return css`
      width: 100%;
      ${generateStylesForResolutionAwareProps(entries)};
      background-size: cover;
      position: relative;

      ${hasOverlay &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: ${overlayColor};
          opacity: ${overlayOpacity};
        }
      `}
    `;
  }}
`;

export const StyledImageContainer = styled(ImageContainer)<ImageContainerProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const StyledHeroVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

export const ScrollIconContainer = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 0;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  z-index: 2;

  ${media.lessThan('md')`
     display: none;
  `}
`;

export const StyledScrollAnchor = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${theme.layout.header.height};
    height: 0;

    ${media.lessThan('sm')`
       bottom: ${theme.layout.header.heightSmaller};
    `}
  `}
`;
